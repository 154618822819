(function() {

    setTimeout(function() {

        var stars = document.querySelectorAll('#reviews form#commentform .stars a');
    
        if (stars) {
            
            stars.forEach(function(star) {

                star.addEventListener('mouseover', function() {
                    var starsBefore = getAllSiblingsBefore(star);
                    
                    starsBefore.forEach(function(activeStars) {
                        activeStars.classList.add('hovered');
                    });
                });

                star.addEventListener('mouseleave', function() {
                    stars.forEach(function(star) {
                        star.classList.remove('hovered');
                    });
                });

                star.addEventListener('click', function() {
                    var starsBefore = getAllSiblingsBefore(star);
                    
                    stars.forEach(function(star) {
                        star.classList.remove('before-active');
                    });

                    starsBefore.forEach(function(activeStars) {
                        activeStars.classList.add('before-active');
                    });
                });

            });

        }

    }, 200);
    

})();
/**
 * Get All Siblings Before
 * @param {object} node 
 * @return {array}
 */
function getAllSiblingsBefore(node) {

    var siblings = [];

    siblings.push(node);

    var node = node.previousSibling;

    while (node) {
        if (!node) break;

        siblings.push(node);

        node = node.previousSibling;
    }

    return siblings;
}

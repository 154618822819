(function() {
    window.addEventListener('DOMContentLoaded', function() {
        var cart = document.querySelectorAll( '.cart, .basket-container, .site-header-cart' );

        cart.forEach(function(cartItem) {
            cartItem.addEventListener( 'mouseover', function() {
                updateScroll(cartItem);
            });
        });

        jQuery( document.body ).on( 'removed_from_cart', function(){
            updateScroll(cart);
        });
    });
})();

function updateScroll(cart) {
    
    if (!cart) {
        return;
    }

    var windowHeight  = window.outerHeight,
        dropdown      = document.querySelectorAll( '.dropdown-menu, .widget_shopping_cart_content' ),
        cartBottomPos = dropdown[0].getBoundingClientRect().bottom,
        cartList      = document.querySelector( '.cart_list' );

    if (document.querySelectorAll('.dropdown-menu-mini-cart').length > 1) {
        document.querySelectorAll('.dropdown-menu-mini-cart')[0].remove();
        updateScroll();
    }
    
    if ( cartBottomPos > (windowHeight - 200) ) {
        cartList.style.maxHeight = '16em';
        cartList.style.overflowY = 'auto';
    }

}

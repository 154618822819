(function() {

    document.addEventListener('DOMContentLoaded', function() {

        var lists = document.querySelectorAll('#secondary .woocommerce-widget-layered-nav-list');
        
        if (lists) {

            /**
             * Loop over each list in Layered Nav and get it's children.
             */
            lists.forEach(function(list) {
                var children = [].slice.call(list.children);
                var collapsible = [];
                
                /**
                 * Loop over each UL with avaible choices.
                 * Push all items into collabsible div, unless its chosen or is the first 3.
                 */
                children.forEach(function(listItem, index) {
                    var chosen = listItem.classList.contains('chosen');

                    if (index > 2 && !chosen) {
                        collapsible.push(listItem);
                        listItem.parentNode.removeChild(listItem);
                    }

                    if (chosen) {
                        listItem.parentNode.removeChild(listItem);
                        list.insertBefore(listItem, list.childNodes[0]);
                    }
                });

                /**
                 * Create div to hold the collapsed items.
                 */
                var div = document.createElement('div');
                
                collapsible.forEach(function(collapseItem) {
                    div.append(collapseItem);
                });

                // Append Div to List
                list.append(div);
                
                // Save the height for later
                var height = div.clientHeight;

                // Add the collapsible class to hide
                div.classList.add('collapsible');

                /**
                 * Add Button to open the collapsed options.
                 */
                if (collapsible.length) {
                    var expand = document.createElement('button');
                    expand.classList.add('collapsible-button');
                    expand.innerHTML = '<span><i class="fas fa-plus"></i> Flere Muligheder...</span>';
                    list.append(expand);

                    expand.addEventListener('click', function() {
                        if (div.classList.contains('active')) {
                            div.classList.remove('active');
                            div.style.maxHeight = 0 + 'px';
                            expand.innerHTML = '<span><i class="fas fa-plus"></i> Flere Muligheder...';
                        } else {
                            div.classList.add('active');
                            div.style.maxHeight = height + 'px';
                            expand.innerHTML = '<span><i class="fas fa-minus"></i> Mindre Muligheder...</span>';
                        }
                    });
                }
            });

        }

    });

})();
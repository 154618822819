function AjaxSearch(input) {

    this.searchInput = input;
    this.searchInput.setAttribute('autocomplete', 'off');
    this.queryResults = [];
    this.searchResultsContainer = document.createElement('div');
    this.searchResultsContainer.classList.add('search-results');

    // Add toggle button if not footer or 404 Page
    if (!input.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.classList.contains('cactus-handheld-footer-bar') && !document.body.classList.contains('error404')) {
        this.button = document.createElement('button');
        this.button.classList.add('search-toggle');
        this.button.innerHTML = '<i class="fas fa-search"></i>';
        this.searchInput.parentNode.parentNode.parentNode.prepend(this.button);

        this.button.addEventListener('click', function(e) {
            e.stopPropagation();
            this.toggleField();
            this.searchInput.focus();
        }.bind(this));
    } else {
        this.searchInput.parentNode.parentNode.parentNode.parentNode.addEventListener('click', function() {
            this.searchInput.focus();
        }.bind(this));
    }

    window.addEventListener('click', function() {
        this.searchResultsContainer.innerHTML = '';
        this.searchInput.value = '';
        this.hideField();
    }.bind(this));

    this.searchResultsContainer.addEventListener('click', function(e) {
        e.stopPropagation();
    });

    if (document.body.classList.contains('error404')) {
        var submitButton = document.querySelector('.page-404__search button[type="submit"]')
        submitButton.addEventListener('click', function(e) {
            e.stopPropagation();
        });
    }
    
    this.searchInput.addEventListener('click', function(e) { e.stopPropagation(); });
    this.searchInput.addEventListener('keypress', function(e) {
        if(event.keyCode == 13) {
            e.preventDefault();
            window.location.href = '/?s=' + this.searchInput.value + '&post_type=product';
        }
    }.bind(this));
    this.searchInput.addEventListener('keydown', this.queryApi.bind(this));
}

AjaxSearch.prototype.toggleField = function() {
    this.searchInput.value = '';
    this.searchInput.parentNode.parentNode.classList.toggle('active');
}

AjaxSearch.prototype.hideField = function() {
    this.searchInput.parentNode.parentNode.classList.remove('active');
}

AjaxSearch.prototype.isLoading = function() {
    this.searchResultsContainer.innerHTML = '<i class="fas fa-spinner"></i>';
}

AjaxSearch.prototype.queryApi = debounce(function() {
    var query = this.searchValidation(this.searchInput.value);

    if (query) {
        var request = new XMLHttpRequest();

        this.isLoading();
        this.searchInput.parentNode.append(this.searchResultsContainer);

        request.open('POST', adminAjax, true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        request.onreadystatechange = function() {//Call a function when the state changes.
            if(request.readyState == 4 && request.status == 200) {
                this.queryResults = this.formatData(request.responseText);
                this.renderResults();
            } else {
                this.queryResults = [];
                this.renderResults();
            }
        }.bind(this);
        request.send('action=data_fetch&keyword=' + query);
    } else {
        this.renderError('3 tegn påkrævet.');
    }
    
}, 500);

AjaxSearch.prototype.renderError = function(text) {
    this.searchResultsContainer.innerHTML = '';

    this.searchInput.parentNode.append(this.searchResultsContainer);

    var div = document.createElement('div');
    div.classList.add('search-error');
    div.innerHTML = text;

    this.searchResultsContainer.append(div);
}

AjaxSearch.prototype.searchValidation = function(string) {
    if (string && string.length >= 3) {
        return string;
    }
    return false;
}

AjaxSearch.prototype.formatData = function(html) {
    var el = document.createElement('div');
    el.innerHTML = html;

    var queryResults = el.querySelectorAll('a');
    var formatted = [];
    
    queryResults.forEach(function(result) {
        formatted.push( { text: result.text, href: result.href } );
    });

    return formatted;
}

AjaxSearch.prototype.renderResults = function() {
    this.searchResultsContainer.innerHTML = '';

    if (this.queryResults.length) {
        this.queryResults.forEach(function(result) {
            var resultContainer = document.createElement('a');
            resultContainer.href = result.href;
            resultContainer.text = result.text;
            this.searchResultsContainer.append(resultContainer);
        }.bind(this));
    } else {
        this.searchResultsContainer.innerHTML = '<div class="search-error">Ingen resultater fundet.</div>';
    }
    
}

/**
 * Debounce Function
 * Limit Rate of wich a function can Fire
 * 
 * @param func 
 * @param wait 
 * @param immediate 
 */
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
